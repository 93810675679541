import { useTranslation } from "react-i18next";

import { clearCompletionType } from "../../utils/storage.utils";

import {
  ApplicationCompletionOverlayHeaderClose,
  ApplicationCompletionOverlayHeaderContainer,
  ApplicationCompletionOverlayHeaderDescription,
  ApplicationCompletionOverlayHeaderIcon,
  ApplicationCompletionOverlayHeaderInfo,
  ApplicationCompletionOverlayHeaderTitle,
} from "./application-completion-overlay-header.style";

import { Cancel01Icon, DocumentValidationIcon } from "hugeicons-react";

const ApplicationCompletionOverlayHeader = () => {
  const { t } = useTranslation();

  const handleCompletionClose = () => clearCompletionType();

  return (
    <ApplicationCompletionOverlayHeaderContainer>
      <ApplicationCompletionOverlayHeaderInfo>
        <ApplicationCompletionOverlayHeaderIcon>
          <DocumentValidationIcon />
        </ApplicationCompletionOverlayHeaderIcon>
        <ApplicationCompletionOverlayHeaderTitle>
          {t("Steps to Complete Integration")}
        </ApplicationCompletionOverlayHeaderTitle>
        <ApplicationCompletionOverlayHeaderDescription>
          {t("Complete the following information before integrating with Fai")}
        </ApplicationCompletionOverlayHeaderDescription>
      </ApplicationCompletionOverlayHeaderInfo>
      <ApplicationCompletionOverlayHeaderClose onClick={handleCompletionClose}>
        <Cancel01Icon />
      </ApplicationCompletionOverlayHeaderClose>
    </ApplicationCompletionOverlayHeaderContainer>
  );
};

export default ApplicationCompletionOverlayHeader;
