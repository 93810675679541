import { parseToFloat } from "../../utils/parser.utils";

import Shimmer, {
  SHIMMER_RADIUS,
} from "../../components/shimmer/shimmer.component";

import {
  ApplicationCompletionTrackProgressBar,
  ApplicationCompletionTrackProgressContainer,
  ApplicationCompletionTrackProgressLabel,
} from "./application-completion-track-progress.style";

const ApplicationCompletionTrackProgress = ({
  progressCount = 0,
  completeCount = 0,
  isLoading = false,
}) => {
  const percentage = parseToFloat((progressCount / completeCount) * 100);

  return (
    <ApplicationCompletionTrackProgressContainer>
      {isLoading ? (
        <Shimmer radius={SHIMMER_RADIUS.PX12} width="100%" height="10px" />
      ) : (
        <ApplicationCompletionTrackProgressBar percent={percentage} />
      )}
      {isLoading ? (
        <Shimmer
          radius={SHIMMER_RADIUS.PX12}
          width="54px"
          minWidth="54px"
          maxWidth="54px"
          height="22px"
        />
      ) : (
        <ApplicationCompletionTrackProgressLabel>
          {`${percentage.toFixed(1)}%`}
        </ApplicationCompletionTrackProgressLabel>
      )}
    </ApplicationCompletionTrackProgressContainer>
  );
};

export default ApplicationCompletionTrackProgress;
