import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { parseToBoolean } from "../../utils/parser.utils";
import { isEmpty } from "../../utils/validation.utils";

import { INTEGRATION_FAI_COMPLETIONS } from "../../constants/integration-completion.constant";

import { getCurrentMarketId } from "../../store/global/global.selector";
import { fetchRegisterFaiStatusStart } from "../../store/integration/integration.action";
import {
  getFaiRegisterStatus,
  getFetchRegisterFaiStatusLoading,
  getIsFetchRegisterFaiStatusHitted,
} from "../../store/integration/integration.selector";

import Responsive from "../../components/responsive/responsive.component";
import ApplicationCompletionTrackProgress from "../application-completion-track-progress/application-completion-track-progress.widget";
import ApplicationCompletionTrackList from "../application-completion-track-list/application-completion-track-list.widget";

import {
  ApplicationCompletionTrackContainer,
  ApplicationCompletionTrackDescription,
  ApplicationCompletionTrackTitle,
} from "./application-completion-track.style";

const ApplicationCompletionTrack = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currenMarketId = useSelector(getCurrentMarketId);

  const isFetchRegisterFaiStatusHitted = useSelector(
    getIsFetchRegisterFaiStatusHitted
  );
  const fetchRegisterFaiStatusLoading = useSelector(
    getFetchRegisterFaiStatusLoading
  );
  const faiRegisterStatus = useSelector(getFaiRegisterStatus);

  const [completions, setCompletions] = useState([]);
  const [progressCount, setProgressCount] = useState(0);
  const [completeCount, setCompleteCount] = useState(0);

  const handleRegisterStatus = () => {
    if (isFetchRegisterFaiStatusHitted || fetchRegisterFaiStatusLoading) return;
    dispatch(fetchRegisterFaiStatusStart({ market_id: currenMarketId }));
  };

  useEffect(() => {
    const statusTimeout = setTimeout(handleRegisterStatus, 500);
    return () => clearTimeout(statusTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const faiCrnStatus =
      faiRegisterStatus?.[INTEGRATION_FAI_COMPLETIONS.FAI_BUSINESS_CRN];
    const faiTaxStatus =
      faiRegisterStatus?.[INTEGRATION_FAI_COMPLETIONS.FAI_BUSINESS_TAX];
    const faiLogoStatus =
      faiRegisterStatus?.[INTEGRATION_FAI_COMPLETIONS.FAI_BUSINESS_LOGO];

    const completions = [];
    if (!isEmpty(faiCrnStatus)) completions.push(faiCrnStatus);
    if (!isEmpty(faiTaxStatus)) completions.push(faiTaxStatus);
    if (!isEmpty(faiLogoStatus)) completions.push(faiLogoStatus);

    let progressCount = 0;
    let completeCount = 0;

    for (const completion of completions) {
      const { is_configured, is_required } = completion ?? {};

      if (!parseToBoolean(is_required)) continue;
      if (parseToBoolean(is_configured)) progressCount++;
      completeCount++;
    }

    setCompletions(completions);
    setProgressCount(progressCount);
    setCompleteCount(completeCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faiRegisterStatus]);

  return (
    <ApplicationCompletionTrackContainer>
      <Responsive>
        <Responsive.Row rowGap="0.35rem">
          <Responsive.Col>
            <ApplicationCompletionTrackTitle>
              {t("Complete Business Profile")}
            </ApplicationCompletionTrackTitle>
            <ApplicationCompletionTrackDescription>
              {t(
                "Complete the following information before integrating with Fai"
              )}
            </ApplicationCompletionTrackDescription>
          </Responsive.Col>
          <Responsive.Col></Responsive.Col>
          <Responsive.Col>
            <ApplicationCompletionTrackProgress
              progressCount={progressCount}
              completeCount={completeCount}
              isLoading={
                !isFetchRegisterFaiStatusHitted && fetchRegisterFaiStatusLoading
              }
            />
          </Responsive.Col>
          <Responsive.Col></Responsive.Col>
          <Responsive.Col></Responsive.Col>
          <Responsive.Col>
            <ApplicationCompletionTrackList
              completions={completions}
              isLoading={
                !isFetchRegisterFaiStatusHitted && fetchRegisterFaiStatusLoading
              }
            />
          </Responsive.Col>
        </Responsive.Row>
      </Responsive>
    </ApplicationCompletionTrackContainer>
  );
};

export default ApplicationCompletionTrack;
